import React from "react";

import "./App.css";
import Navigation from "./components/Navigation";
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import DataSecurity from './pages/DataSecurity';
import JobsPage from './pages/JobsPage';
import Page404 from './pages/Page404';
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const App = () => {
  return (
   <div id="app">
   <Router>
   <Navigation />
  
     <Routes>
     
       <Route path="/" element={<Home />} />
       <Route path="/impressum" element={<Impressum />} />
       <Route path="/datenschutz" element={<DataSecurity />} />
       <Route path="/jobs" element={<JobsPage />} />
       <Route path="/*" element={<Page404 />} />

     </Routes>
  
<Footer />

   </Router>
   </div>
  );
}

export default App;

