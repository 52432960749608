const JobsPage = () => {
    return(
        <div id="jobs" className="container-fluid sp-container"> 
            <h2>Stellenangebot</h2>
            <p>Für unseren Sitz in Koblenz suchen wir ab sofort einen betriebswirtschaftlichen Berater
(m/w/d).</p>
<h2>Ihre Aufgaben:</h2>
<ul>
    <li>Kompetente, zielorientierte wirtschaftliche Beratung unserer Mandanten</li>
    <li>Betriebswirtschaftliche Analysen und Wirtschaftlichkeitsberechnungen</li>
    <li>Erstellen von Leistungsentgeltkalkulationen und Unterstützung in den anschließenden
Verhandlungen</li>
    <li>Eigenverantwortliche Organisation des Arbeitsbereiches</li>
</ul>
<h2>Ihr Profil</h2>
<ul>
    <li>Abgeschlossene oder angehende Hochschulausbildung/-studium Fachwirt Alten-
/Krankenpflege, Fachwirt Sozial-/Gesundheitswesen oder Betriebswirtschaft. Gerne
auch vergleichbare Ausbildungen mit betriebswirtschaftlichen Hintergründen.</li>
    <li>Idealerweise Berufserfahrung in der Beratung von Einrichtungen der Pflege- und
Sozialwirtschaft</li>
    <li>Hohes Maß an Motivation und Flexibilität</li>
    <li>Sehr gute Kommunikationsfähigkeit und organisatorisches Geschick</li>
    <li>Sicherer Umgang mit allen Office-Anwendungen</li>
</ul>
<h2>Unser Profil</h2>
<ul>
    <li>Eine verantwortungsvolle Arbeit in einem professionellen und dynamischen
Arbeitsumfeld</li>
    <li>Hoher Gestaltungsspielraum bei einem vielseitigem Aufgabenfeld</li>
    <li>Entspannte Arbeitsatmosphäre in modernen Büroräumen in der Innenstadt von Koblenz</li>
    <li>Kurze Entscheidungswege und Platz für eigene innovative Ideen</li>
    <li>Individuelle Fort- und Weiterbildungsmöglichkeiten</li>
    <li>Eine Ihrer Qualifikation und den Anforderungen entsprechende Vergütung</li>
    <li>Gratifikationen und die Option auf einen Dienstwagen</li>
</ul>
<p>
Sie erwartet ein interessantes und abwechslungsreiches Aufgabenfeld in einem engagierten
Team. Wir bieten Ihnen einen hohen Gestaltungsspielraum, um Ihrem Engagement und
Verantwortungsbewusstsein gerecht zu werden.
</p>
<p>
Bitte richten Sie Ihre aussagekräftige Bewerbung unter Angabe Ihrer Gehaltsvorstellung als
PDF-Dokument in digitaler Form mit dem Betreff "Betriebswirtschaftlicher Berater" an 
<a href="mailto:info@roba-con.de"> info@roba-con.de</a> 
</p>
        </div>
    )
}
export default JobsPage;