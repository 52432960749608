const Impressum = () => {
    return(
        <div id="impressum" className="container-fluid sp-container">
           <h2>Impressum</h2>
<p>ROBA-CON GmbH<br />
Schloßstraße 5<br />56068 Koblenz</p>
<p>Telefon: 0261/9623461-0<br />
Telefax: 0261/9623461-9<br />
E-Mail: <a href="mailto:info@roba-con.de">info@roba-con.de</a><br />
</p>
<p><strong>Vertreten durch:</strong><br />Bwt. (VWA) Olaf Roßbach, Alice
Roßbach</p><p><strong>Registereintrag:</strong><br />Eingetragen im
Handelsregister.<br />Registergericht: Amtsgericht Koblenz<br />Registernummer:
HRB 27902</p><p><strong>Umsatzsteuer-ID: </strong><br
/>Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:<br
/>DE815897998</p><p><strong>Berufshaftpflichtversicherung:
</strong>Württemberische Versicherung AG <br />
70163 Stuttgart<br />Geltungsbereich: Deutschland</p><p>Quelle: <a
href="https://www.juraforum.de">Zum Artikel</a></p>
        </div>
    )
}

export default Impressum;