import React from "react";
import "./WorkSection.css";
import "animate.css/animate.min.css";
import workImg from '../assets/teamimg.jpg';

const WorkSection = () =>{
    return(
        <div id="work" className="container-fluid roba-section">
        <i id="work-bg"></i>

            <div className="row row-work">
            <div className="col-md-6"><img className="team-image" src={workImg} alt="leistungen" /></div>               
            <div className="col-md-6 start-content-col">
            <div>
            <h2 className="work-h2">Unsere Gesellschaft bietet eine ganzheitliche bundesweite Unternehmensberatung</h2>
            <p>für Einrichtungen der Pflege- und Sozialwirtschaften an. Unsere Beratungsleistungen zielen auf einen positiven unternehmerischen Erfolg und auf eine wirtschaftliche Optimierung der betrieblichen Prozesse ab. Wir passen unser Beratungsspektrum an die dynamische Entwicklung der
            Rahmenbedingen und der Gesetzgebung in der Branche der Pflege- und
            Sozialwirtschaft an. Das Beratungsangebot der ROBA-CON GmbH gliedert
            sich in drei große Bereiche auf, die auf Ihre individuellen
            Bedürfnisse abgestimmt werden.</p>
            </div>
            </div>
            </div>
        </div>
    )
}

export default WorkSection;