import React from "react";
import "./AboutSection.css";
import "animate.css/animate.min.css";
import logo from "../assets/logo.svg"
import startImg from '../assets/aboutbg1.png';



const AboutSection = () =>{
    return(
       
        <div id="about" className="container-fluid roba-section">

            <div className="row start-row">
            
            <div className="col-sm-6  start-content-col">
             <img src={logo} height="85px" alt="roba-con logo" style={{margin: "4rem 0",maxWidth: "300px"}}/>
           <p>"Begeisterung ist der nie erlahmende Impuls, der uns beharrlich unser Ziel verfolgen lässt!"</p>
          <h3> - Norman V. Peale</h3>
                </div>

                <div className="col-sm-6  flex-column-reverse start-content-col">
                <img style={{display:"inline-block", width:"100%", height:"100%" }} src={startImg} alt="roba-start"/>
                </div>
            </div>
        
        </div>
       
    )
}

export default AboutSection;