import React,{useState} from "react";
import "animate.css/animate.min.css";
import {Icon} from '@iconify/react';

import "./TeamList.css";
import '../fontello/css/fontello.css';




const TeamList = () => {
    const [isOpt, setIsOpt] = useState(false);
    const [isQuali, setIsQuali] = useState(false);
    const [isCon, setIsCon] = useState(false);
    const isOptHandler = () =>{
        setIsOpt(prev => !prev);
    }
    const isQualiHandler = () =>{
        setIsQuali(prev => !prev);
    }
    const isConHandler = () => {
        setIsCon(prev => !prev);
    }
    return(
        <div id="team-list" className="container-fluid">
            <h2 style={{textAlign:"center",margin:"3rem 0", fontWeight:"bold", color:"#eb6b0f"}}>Leistungen der ROBA-CON GmbH</h2>
            <div className="row">
            <div className="short-col"><Icon className="roba-icon" icon="bi:clipboard-data" color="#2e2a5a" width="85" height="85" inline={true}/></div>

                <div className="long-col"><h2 style={{color:"#2e2a5a" }}>Optimierung von Erträgen</h2>
                <ul
                        style={{
                            padding:"0 1rem"
                            }}
                    >
                      
                        <li className="card-item">
                        Erstellen von Pflegesatzanträgen und Verhandlungsunterlagen nach dem 8. Kapitel SGB XI und dem 10. Kapitel SGB XII
                        </li>
                        <li className="card-item">
                        Führen von zielorientierten Verhandlungen mit den Pflegekassen und/oder Sozialhilfeträgern
                        </li>

                        <li className="card-item">
                        Verhandlungen von Leistungs- und Qualitätsmerkmalen nach § 84 Abs. 5 SGB XI, Leistungsvereinbarungen nach § 77 SGB XII
                        </li>
{isOpt && <>
    <li className="card-item">
    Erstellen von Schiedsstellenanträgen und Vertretung vor den Schiedsstellen als Weiterführung der Verhandlungen
                        </li>

                        <li className="card-item">
                        Zustimmungsverfahren für betriebsnotwendige Investitionsaufwendungen 
                        </li>

                        <li className="card-item">
                        Beratung im Rahmen von Förderverfahren
                        </li>
            
                        <li className="card-item">
                        Unterstützung bei Regressansprüchen der Kostenträger
                        </li>    
</>
                        }
                        <span onClick={isOptHandler}><b>{isOpt ? "ZURÜCK" : "MEHR"}</b></span>

                    </ul>
</div>
            </div>
            <div className="row">
                <div className="short-col"><Icon icon="bi:patch-check" color="#2e2a5a" width="85" height="85" inline={true}/></div>
            <div className="long-col">
            <h2 style={{color:"#2e2a5a"}}>Qualitätsmanagement</h2>
<ul
                        style={{
                            padding:"0 1rem"
                            }}
                    >
                     
                        <li className="card-item">
                        Unterstützung bei der Qualitätsprüfung durch den Medizinischen Dienst
                        </li>
                    
                        <li className="card-item">
                        Unterstützung bei Prüfverfahren der regionalen Aufsichtsbehörden
                        </li>
                        <li className="card-item">
                        Dienstplananalysen zur bestmöglichen Strukturierung der knappen Personalressourcen
                        </li>
                        {isQuali && <>
                    
                        <li className="card-item">
                        Beratung zur Optimierung der Pflegegradstrukturen
                        </li>
                   
                        <li className="card-item">
                        Personalabgleiche nach dem Dienstplankalkül
                        </li>
                        </>}
                        <span onClick={isQualiHandler}><b>{isQuali ? "ZURÜCK" : "MEHR"}</b></span>

                    </ul></div>
            </div>
            <div className="row">
            <div className="short-col"><Icon icon="bi:chat-square-text" color="#2e2a5a" width="85" height="85" inline={true}/>
</div>
                <div className="long-col"><h2 style={{color:"#2e2a5a"}}>Betriebswirtschaftliche Beratung</h2>
                <ul
                        style={{
                            padding:"0 1rem"
                            }}
                    >
                     
                        <li className="card-item">
                        Beratung bei der Projektierung, Realisierung und Inbetriebnahme von Einrichtungen der Pflege- und Sozialwirtschaft
                        </li>
                    
                        <li className="card-item">
                        Optimierung der Betriebsprozesse, Budgetierungen und Planungsrechnungen
                        </li>
                    
                        <li className="card-item">
                        Beratung bei Nachfolgeregelungen, Kauf- und Verkauf von Einrichtungen der Pflege- und Sozialwirtschaft
                        </li>
                     {isCon && <>
                        <li className="card-item">
                        Gutachterliche Stellungnahmen zu wirtschaftlichen Fragen von Einrichtungen der Pflege- und Sozialwirtschaft
                        </li>
                    
                        <li className="card-item">
                        Umstellungen auf ein Entlohnungssystem
                        </li>
                        <li className="card-item">
                        Hilfe bei der Einrichtung der Kosten- u. Leistungsrechnung gemäß Pflegebuchführungsverordnung
                        </li>
                     
                        <li className="card-item">
                        In-House-Seminare zur erfolgreichen Unternehmensführung von Einrichtungen der Pflege- und Sozialwirtschaft

                        </li>
                    
                        <li className="card-item">
                        Beratung zur Optimierung der Pflegesatzgestaltung, u.a. Gründung von Cateringsystem oder Outsourcing

                        </li>
                     </>
                       }
                       <span onClick={isConHandler}><b>{isCon ? "ZURÜCK" : "MEHR"}</b></span>

                    </ul>
</div>
            </div>
        </div>

    )
}

export default TeamList;