import React from "react";
import "./Navigation.css"
import {Icon} from '@iconify/react';

const Navigation = () =>{
    return(
      <div className="container-fluid nav-container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
             
              <li className="nav-item">
                <a className="nav-link" href="/#work">Leistungen</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/#team">Team</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#contact">Kontakt</a>
              </li>
            </ul>
          </div>
          <div className="nav-icon ml-auto">
        <a href="https://vcard.roba-con.de"><Icon className="roba-icon" icon="wpf:add-user" color="#2e2a5a" width="35" height="35" inline={true} /></a>
          </div>
      </nav>
      
      </div>


    )

}
export default Navigation;