import React,{useState} from "react";
import member1 from "../assets/member1.png";
import member2 from "../assets/member2.png";
import member3 from "../assets/member3.png";
import member4 from "../assets/member4.png";
import Slider from 'react-slick';
import './TeamSection.css';
import slider0 from '../assets/slider0.png';
import slider1 from '../assets/slider1.png';
import slider2 from '../assets/slider2.png';
import slider3 from '../assets/slider3.png';
import slider4 from '../assets/slider4.png';

const TeamSection = () => {
  const [isOpt, setIsOpt] = useState(false);
  const [isQuali, setIsQuali] = useState(false);
  const [isCon, setIsCon] = useState(false);
  const [isWuff, setIsWuff] = useState(false);
  const isWuffHandler = () => {
    setIsWuff(prev => !prev);
  }
  const isOptHandler = () =>{
      setIsOpt(prev => !prev);
  }
  const isQualiHandler = () =>{
      setIsQuali(prev => !prev);
  }
  const isConHandler = () => {
      setIsCon(prev => !prev);
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 9000,
    autoplay:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    cssEase: 'linear'
  };
  return(
      <div id="team" className="container-fluid">
          <h2 style={{textAlign:"center",margin:"3rem 0", fontWeight:"bold", color:"#eb6b0f"}}>Unser Team</h2>
          <div className="row">
          <div className="short-col col-sm-6 team-col"><img src={member2} alt="Olaf Rossbach" /></div>

              <div className="long-col team-lg"><h2 style={{color:"#2e2a5a" }}>Olaf Roßbach</h2>
              <ul>
                <li><i className="icon-phone icon"/><a href="tel:+49(261)96234611">+49 (261) 9623461-1</a></li>
                <li><i className="icon-mail-alt icon"/><a href="mailto:orossbach@roba-con.de">orossbach@roba-con.de</a></li>
              </ul>
              <hr />
          <p>geb. am 16.05.1968 in Koblenz, ist Liebhaber von gutem Essen, guten Weinen sowie zielführenden Gesprächen und pragmatischen Lösungen, frei nach dem Motto „In der Kürze liegt die Würze“.</p>
{isOpt && <><p>Nach der Ausbildung zum Steuerfachangestellten und Abschluss des Studiengangs zum Betriebswirt VWA, der ihm Kenntnisse auf Hochschulniveau in der Betriebs- und Volkswirtschaftslehre sowie der Rechtswissenschaft vermittelt hat, berät er unter Aspekten der wirtschaftlichen Optimierung seit 1992 Einrichtungen der Pflege- und Sozialwirtschaft. Die Dynamische Entwicklung der Branche seit Einführung der Pflegeversicherung ist ihm vertraut.
Das in den letzten drei Jahrzehnten gewonnene umfangreiche Fachwissen im Umgang mit den Kostenträgern und Behörden in z.B. Pflegesatzverhandlungen, stellt er gerne seinen Geschäftspartnern zur Verfügung. 
Als Betreiber einer interdisziplinäreren Pflegeeinrichtung in den Jahren 2009 bis 2020 sind ihm die Sorgen und Nöte von Betreibern in der der Pflege- und Sozialwirtschaft bekannt.</p></>
                      }
                      <span onClick={isOptHandler}><b>{isOpt ? "ZURÜCK" : "MEHR"}</b></span>

                 
</div>
          </div>
          <div className="row">
              <div className="short-col col-sm-6  team-col"><img src={member3} alt="Alice Rossbach" /></div>
          <div className="long-col team-lg">
          <h2 style={{color:"#2e2a5a"}}>Alice Roßbach</h2>
          <ul>
                <li><i className="icon-phone icon"/><a href="tel:+49(261)96234610">+49 (261) 9623461-0</a></li>
                <li><i className="icon-mail-alt icon"/><a href="mailto:arossbach@roba-con.de">arossbach@roba-con.de</a></li>
              </ul>
              <hr />
<p>ist bekannt für kritische Anmerkungen zu gewohnten Prozessen.
Nach Ihrer Ausbildung zum Kaufmann, war Sie jahrelang als Assistentin der Geschäftsführung in einer Wirtschaftsprüfungsgesellschaft tätig.</p>
                      {isQuali && <>                   <p>Als Betreiberin einer interdisziplinäreren Pflegeeinrichtung in den Jahren 2009 bis 2020 hat Sie Ihr Organisations- und Finanztalent in der der Pflege- und Sozialwirtschaft weiterentwickeln können und unterstützt seit 2020 die ROBA-CON GmbH hinsichtlich Strategie und Organisation.</p>
</>}
                      <span onClick={isQualiHandler}><b>{isQuali ? "ZURÜCK" : "MEHR"}</b></span>

                 </div>
          </div>
          <div className="row">
          <div className="short-col col-sm-6 team-col"><img src={member1} alt="André Schäfer" />
</div>
              <div className="long-col team-lg"><h2 style={{color:"#2e2a5a"}}>André Schäfer</h2>
              <ul>
                <li><i className="icon-phone icon"/><a href="tel:+49(261)96234612">+49 (261) 9623461-2</a></li>
                <li><i className="icon-mail-alt icon"/><a href="mailto:aschaefer@roba-con.de">aschaefer@roba-con.de</a></li>
              </ul>
              <hr />
                      <p>lebt nach dem Motto von Don Quijote: „Weil Speis und Trank in dieser Welt doch Leib und Seel' zusammenhält“.</p>
                   {isCon && <>
                    <p>Nach der Ausbildung als Kaufmann für Versicherungen und Finanzen, machte er sich als freier Makler selbstständig. Nach der Spezialisierung auf Einrichtungen der Pflege- und Sozialwirtschaft, wuchs das Interesse und die Begeisterung an diesem Themengebiet. Seit der Gründung der ROBA-CON GmbH im Jahr 2020 und als angehender Fachwirt im Bereich Gesundheits- und Sozialwesen begleitet er die Betreiber beharrlich durch sämtliche Leistungsangebote der ROBA-CON GmbH.
</p>

                   </>
                     }
                     <span onClick={isConHandler}><b>{isCon ? "ZURÜCK" : "MEHR"}</b></span>

               
</div>
          </div>
          <div className="row">
          <div className="short-col col-sm-6 team-col"><img src={member4} alt="Gino" />
</div>
              <div className="long-col col-sm-6 team-lg"><h2 style={{color:"#2e2a5a"}}>Gino</h2>
              <ul>
                <li>geb. am 08.09.2021</li>
              </ul>
              <hr />
                      <p>Ist für Chaos und gute Stimmung verantwortlich.</p>
                      {isWuff && <><h2 style={{color:"#eb6b0f", margin:"2rem"}}>Wuff</h2></>}
                  
                     <span onClick={isWuffHandler}><b>{isWuff ? "ZURÜCK" : "MEHR"}</b></span>

               
</div>
          </div>
          <div className="row slider-row">
            <Slider {...settings}>
              <div className="slider-item">
              <img src={slider0} alt="team"/>
              </div>

             

              <div className="slider-item">
              <img src={slider2} alt="team"/>
              </div>

              <div className="slider-item">
              <img src={slider1} alt="team"/>
              </div>

              <div className="slider-item">
              <img src={slider3} alt="team"/>
              </div>

              <div className="slider-item">
              <img src={slider4} alt="team"/>
              </div>
            </Slider>
          </div>
      </div>

  )}

export default TeamSection;