
const Page404 = () => {
    return(
        <div>
            <h1>404</h1>
            <h2>SEITE NICHT GEFUNDEN</h2>
            <p>Die gesuchte Seite konnte leider nicht gefunden werden</p>
            <button><a href="https://www.roba-con.de">Startseite</a></button>
        </div>
    )
}

export default Page404;