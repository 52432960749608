import AboutSection from "../components/AboutSection";
import WorkSection from "../components/WorkSection";
import TeamList from "../components/TeamList";
import Contact from "../components/Contact";
import TeamSection from "../components/TeamSection";
import Jobs from "../components/Jobs";

const Home = () => {
    return(
<>
        <AboutSection />
        <WorkSection />
       <TeamList />
       <TeamSection />
       <Jobs />
     <Contact /> 
     </>
    )
}
export default Home;