import React from "react";
import "./Jobs.css";
import RobaButton from "./RobaButton";
import "animate.css/animate.min.css";
import {Link} from 'react-router-dom';

const Jobs = ()=>{
    return(
        <div id="contact" className="container-fluid roba-section">
           <div className="row">
<div className="col-sm-6 contact-col">

<h2>Stellenangebote</h2>
<hr />
<p>
Die ROBA-CON GmbH, als ein familiär geführtes Unternehmen, unterstützt bundesweit
Einrichtungen der Pflege- und Sozialwirtschaft in Fragen der Wirtschaftlichkeit. Im Vordergrund
stehen die Leistungsentgeltverfahren für voll- und teilstationäre Pflegeeinrichtungen.
</p>
<RobaButton>
<h2><Link to="jobs/#">Jobs</Link></h2>
</RobaButton>

</div>
<div className="col-sm-6 contact-col">
 <img src="https://vcard.roba-con.de/static/media/mini_logo.8be80214.svg" alt="phone" height="200px" className="contact-img" /> 
</div>

</div>
        </div>
    )
}

export default Jobs;