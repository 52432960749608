import React from "react";
import "./Contact.css";
import phone from "../assets/phone.png";
import RobaButton from "./RobaButton";
import "animate.css/animate.min.css";

const Contact = ()=>{
    return(
        <div id="contact" className="container-fluid roba-section">
            <div className="row">
                <div className="col-sm-6 contact-col">
                 <img src={phone} alt="phone" className="contact-img" /> 
                </div>
                <div className="col-sm-6 contact-col">
             
                <h2>Noch Fragen?</h2>
             <hr />
<p>
Für unsere Mandantinnen und Mandanten optimale Ergebnisse zu erzielen, ist unsere oberste Priorität. Dabei sind wir auch gerne bereit Kompromisse einzugehen, denn das Ziel ist der Weg und nicht der Weg das Ziel.
</p>
<RobaButton><h2><a href="https://vcard.roba-con.de">Anrufen</a></h2></RobaButton>
          
                </div>
            </div>
           
        </div>
    )
}

export default Contact;